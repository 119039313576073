<template lang="pug">
component.button(
  :is="componentType",
  :to="effectiveTo",
  :href="effectiveHref",
  :class="[{ loading, disabled, plain, rounded }, type, size]"
)
  .label(:class="{ loading }")
    .icon(v-if="icon", style="display: inline-block; margin-right: 8px;")
      ClientOnly(fallback-tag="i")
        fa(v-if="icon" :icon="icon")

    span {{ label }}

  .loading-container(v-if="loading")
    Loading(:color="loadingColor")
</template>
<script setup lang="ts">
import type { RouteLocationRaw } from "vue-router"

import { computed } from "vue"

const props = defineProps({
  type: {
    type: String,
    default: "primary",
    validator: (val: string) => ["primary", "text"].includes(val),
  },
  size: {
    type: String,
    default: "normal",
    validator: (val: string) => ["small", "normal", "large"].includes(val),
  },
  plain: {
    type: Boolean,
    default: false
  },
  rounded: {
    type: Boolean,
    default: false
  },
  label: {
    type: String,
  },
  icon: {
    type: Array<String>,
    default: () => null
  },
  disabled: {
    type: Boolean,
    default: false
  },
  loading: {
    type: Boolean,
    default: false
  },
  internal: {
    type: Boolean,
    default: true,
  },
  to: {
    type: [ String, Object ] as PropType<RouteLocationRaw>,
  },
})

const componentType = computed(() => {
  if (!props.disabled && props.to && props.internal) {
    return resolveComponent('NuxtLink')
  } else {
    return 'a'
  }
})

const effectiveTo = computed(() => {
  if (!props.disabled && props.to && props.internal) {
    return props.to
  } else {
    return undefined
  }
})

const effectiveHref = computed(() => {
  if (!props.disabled && props.to && props.internal) {
    return undefined
  } else {
    return props.to
  }
})

const loadingColor = computed(() => {
  if (!props.plain) {
    return "#FFF"
  } else {
    return "#EE3D8A"
  }
})
</script>
<style lang="sass">
@import "~/assets/styles/variables.sass"

$extra-light-magenta: scale-color($magenta, $lightness: +65%)
$light-magenta: scale-color($magenta, $lightness: +15%)

.button + .button
  margin-left: 8px

.button
  position: relative
  display: inline-flex
  justify-content: center
  align-items: center
  height: 40px
  line-height: 40px
  padding: 0 16px
  color: $magenta
  font-size: 16px
  font-weight: bold
  cursor: pointer
  user-select: none
  border-radius: 4px

  &.small
    height: 32px
    line-height: 32px
    font-size: 14px

  &.large
    height: 44px
    line-height: 44px
    font-size: 20px
    padding: 0 24px
    min-width: 240px

  &.rounded
    border-radius: 50px

  &.disabled
    color: $inactiveTextColor
    cursor: default
    pointer-events: none

  &.loading
    pointer-events: none

  &.text

    &:hover
      background-color: rgba(0, 0, 0 , 0.03)

  &.primary
    background-color: $magenta
    color: $white
    border: 1px solid $magenta

    &:hover
      background-color: $light-magenta
      border-color: $light-magenta

    &.plain
      background-color: rgba(255, 255, 255, 0)
      color: $magenta

      &.disabled
        background-color: white
        color: $extra-light-magenta
        border-color: $extra-light-magenta

        &:hover
          color: $extra-light-magenta
          border-color: $extra-light-magenta

      &:hover
        color: $light-magenta
        border-color: $light-magenta

    &.disabled
      background-color: $extra-light-magenta
      border-color: $extra-light-magenta

  .label
    display: block
    vertical-align: middle
    color: inherit
    font: inherit
    text-align: center

    &.loading
      opacity: 0

  .loading-container
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    display: flex
    justify-content: center
    align-items: center
    font-size: .6em
</style>
<style lang="sass">
.button
  .label
    *
      color: inherit

</style>
